<template>
  <div class="findmima">
    <div class="title">找回密码</div>
    <div class="content">
      <el-steps :active="active" >
        <el-step title="1 确认账号" ></el-step>
        <el-step title="2 接收填写邮箱验证码" ></el-step>
        <el-step title="3 重置登录密码" ></el-step>
      </el-steps>
      <div class="active" v-if="active==1">
        <el-input v-model="tenantId" placeholder="请输入您注册时的统一社会信用代码" style="width:350px;display: block;margin-bottom:20px;"></el-input>
        <el-input v-model="email" placeholder="请输入您注册时的邮箱" style="width:350px;display: block"></el-input>
        <el-popover
            placement="bottom"
            width="200"
            trigger="click"
            content="请您联系系统管理员重置密码，0317-5223280。">
          <div class="femail" slot="reference">忘记邮箱?</div>
        </el-popover>
        <el-button class="btn" type="primary" @click="go(2)">下一步</el-button>
      </div>
      <div class="active" v-if="active==2">
        <el-button @click="fasong" type="primary" :disabled="disabled" style="margin-bottom: 20px;">{{disabled?'已发送('+num+')':'点击发送验证码邮件'}}</el-button>
        <el-input v-model="tenantId" style="width:350px;display: block;"></el-input>
        <el-input v-model="yanzhengma" style="width: 350px;display: block;margin-top: 20px" placeholder="请输入您邮件收到的验证码"></el-input>
        <el-button class="btn" type="primary" @click="go(3)">下一步</el-button>
        <el-button class="btn" @click="go(1)">返回</el-button>
      </div>
      <div class="active" v-if="active==3">
        <el-input v-model="tenantId" style="width:350px;display: block;"></el-input>
        <el-input v-model="password" placeholder="请输入新的登录密码" style="width: 350px;display: block; margin-top: 20px" show-password></el-input>
        <el-input v-model="password1" placeholder="请重新输入新的登录密码" style="width: 350px;display: block;margin-top: 20px" show-password></el-input>
        <el-button class="btn" type="primary" @click="tijiao()">确认</el-button>
        <el-button class="btn" @click="go(2)">返回</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import {checkEmailIsExist, sendValidateCode, updatePasswordByEmail} from "../../request/moudle";

export default {
  name: "findmima",
  data(){
    return {
      active:1,
      tenantId:'',
      email:'',
      yanzhengma:'',
      password:'',
      password1:'',
      disabled:false,
      num:60,
      timer:null,
      validateKey:''
    }
  },
  methods:{
    go(number){
      if(number==1){
        this.active=number;
        console.log(this.active,number)
      }else if(number==2){
        this.$api.index.checkEmailIsExist({
          email:this.email,
          tenantId: this.tenantId,
        }).then((res)=>{
          this.active=number
        })
      }else if(number==3){
        this.$api.index.checkEmailValidateCode({
          email:this.email,
          code:this.yanzhengma
        }).then((res)=>{
          this.active=number
        })
      }
    },
    tijiao(){
      if(this.password!=this.password1){
        this.$message({
          message: '两次密码不一致',
          type: 'warning'
        });
        return
      }
      this.$api.index.updatePasswordByEmail({
        code:this.yanzhengma,
        email:this.email,
        password:this.password,
        tenantId: this.tenantId,
      }).then((res)=>{
        this.$message({
          message: '修改成功',
          type: 'success'
        });
        this.$router.replace({
          path:'/login'
        })
      })
    },
    fasong(){
      this.$api.index.sendValidateCode({
        email: this.email,
        tenantId: this.tenantId,
      }).then((res)=>{
        this.$message({
          message: '发送成功',
          type: 'success'
        });
      })
      this.disabled=true
      this.countDown()
    },
    countDown(){
      this.num--;
      if(this.num == 0){
        this.num = 60
        this.disabled=false
        clearTimeout(this.timer)
        return
      }
      this.timer = setTimeout(()=>{
        this.countDown()
      }, 1000);
    }
  }
}
</script>

<style lang="scss" scoped>
.title{
  width: 1200px;
  text-align: center;
  line-height: 100px;
  font-size: 22px;
  margin: 0 auto;
}
.femail{
  margin-left: 295px;
  margin-top: 15px;
  color: #999999;
  width: 80px;
  cursor: pointer;
}
.content{
  width: 1200px;
  margin: 0 auto;
  .active{
    padding: 60px 0;
    width: 500px;
    margin: 0 auto;
  }
  .btn{
    margin-top: 30px;
    margin-right: 50px;
  }
}
</style>